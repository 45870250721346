import React, { useEffect, useState } from "react";
import {
  BuyOnlineHere,
  Footer,
  Header,
  LoadingBar,
  MapView,
  OurFaq,
  PassBanner,
  PassesPaymentText,
  PurchaseOurPass,
  PurchasePassLocation,
  SafetyInfo,
} from "../components";
import { images } from "../actions/customFn";
import { motion } from "framer-motion";
import { useGetPageDataMutation } from "../Redux/RTKQ/pageApiSlice";
import { PageTitleHelmet } from "../hooks/PageTitleHelmet";

const BuyPasses = () => {
  const googleKey = "AIzaSyA9Fe-I6MXnwH5s-FHYXRQ3jMqrgzEQo2k";

  const [getPageById, { data, error, isLoading }] = useGetPageDataMutation();

  useEffect(() => {
    getPageById(3);
  }, []);

  const pageData = (data && data.data) || {};

  const fitBounds = async (map) => {
    let bounds = new window.google.maps.LatLngBounds();
    let isSingleLocation = false;

    if (map != null) {
      bounds.extend(
        new window.google.maps.LatLng(22.7527421, 75.88371599999999)
      );

      isSingleLocation = true;

      if (isSingleLocation == true) {
        let zoomLevel = 0.01; //Increase value : Zoom-out, Decrease value:Zoom In
        const singleLocation = bounds.getCenter();
        bounds.extend(
          new window.google.maps.LatLng(
            singleLocation.lat() + zoomLevel,
            singleLocation.lng() + zoomLevel
          )
        );
        bounds.extend(
          new window.google.maps.LatLng(
            singleLocation.lat() - zoomLevel,
            singleLocation.lng() - zoomLevel
          )
        );
      }
    }
    await map.fitBounds(bounds);
  };
  const handleMapMounted = (map) => {
    if (map != null) {
      fitBounds(map);
    }
  };

  const [isload, setIsload] = useState(true);

  setTimeout(() => {
    setIsload(false);
  }, 1500);

  return (
    <motion.div
      initial={{ opacity: 0.2 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 1 }}
      transition={{ duration: 2 }}
    >
      <PageTitleHelmet />
      {isload == true ? (
        <LoadingBar />
      ) : (
        <>
          <PassBanner
            heading={pageData?.buy_passes_heading}
            banner_image={pageData?.buy_passes_image}
          />

          <div className="buypass_pagepurchasepass">
            <PurchaseOurPass
              heading={pageData?.our_app_heading}
              our_app_image={pageData?.our_app_image}
              description={pageData?.our_app_description}
              subheading={pageData?.our_app_sub_heading}
              our_app_playstore_url={pageData?.our_app_playstore_url}
              our_app_applestore_url={pageData?.our_app_applestore_url}
            />
          </div>
          <PassesPaymentText data={pageData} />
          <BuyOnlineHere data={pageData} />
          <PurchasePassLocation data={pageData} />

          <div className="google-map-section">
            <div className="map-wrapper">
                <div
                  className="map-iframe-container"
                  dangerouslySetInnerHTML={{ __html: pageData?.google_map }}
                />
            </div>
          </div>

          <OurFaq heading={pageData?.faq_heading} faq_list={pageData?.faq} />
          <SafetyInfo data={pageData} />
        </>
      )}
    </motion.div>
  );
};

export default BuyPasses;
