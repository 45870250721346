import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../../services/API.js";
import { isloginSuccess } from "../Slice/authSlice.js";
import { successMsg } from "../../services/customeFn.js";

export const login = createAsyncThunk(
  "auth/login",
  async (
    { formData, navigate, location, fromBuyPass },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await API.post("/customer-login", formData, config);
      if (data.status) {
        localStorage.setItem("qctl_customer_id", data.customer_id);
        localStorage.setItem("qctl_password", data.password);
        dispatch(isloginSuccess());

        var from = "/dashboard";
        if (data.type == "Vendor") {
          if (fromBuyPass && location.state?.date_of_visit) {
            var from = "/buypass/add";
          }
        } else if(data.type == "Customer") {
          if (fromBuyPass && location.state?.date_of_visit) {
            var from = "/checkout";
          }
        }
        navigate(from, {
          replace: true,
          state: location.state,
        });
        return data.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getCustomerDetails = createAsyncThunk(
  "auth/getCustomerDetails",
  async (_, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const customer_id = localStorage.getItem("qctl_customer_id");
      const password = localStorage.getItem("qctl_password");

      const { data } = await API.post(
        "/customer-profile",
        { customer_id, password },
        config
      );

      if (data.status) {
        return data.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const forgetPassword = createAsyncThunk(
  "auth/forgetPassword",
  async (
    { email, navigate, closeLoginModal, setSignupPart },
    { rejectWithValue }
  ) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await API.post(
        "/customer-forgot-password",
        { email },
        config
      );
      if (data.status) {
        closeLoginModal();
        setSignupPart(false);
        return data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const createNewPassword = createAsyncThunk(
  "auth/createNewPassword",
  async ({ password, token, navigate }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      let email = localStorage.getItem("qctl_forgetEmail");
      let new_password = password;
      const { data } = await API.post(
        "/customer-reset-password",
        { new_password, token, email },
        config
      );

      if (data.status) {
        navigate("/");
        return data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const createPurchasePassbyVendor = createAsyncThunk(
  "auth/createPurchasePassbyVendor",
  async ({ formDataToSend, setFormData }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const customer_id = localStorage.getItem("qctl_customer_id");
      const password = localStorage.getItem("qctl_password");
      formDataToSend.append("customer_id", customer_id);
      formDataToSend.append("password", password);
      formDataToSend.append("type", "Vendor");

      const { data } = await API.post(
        "/purchase-pass-vendor",
        formDataToSend,
        config
      );

      if (data.status) {
        setFormData({
          full_name: "",
          email: "",
          date_of_visit: "",
          number_of_people: "",
          trackingpass_id: "",
          tracking_type: "Cycling",
        });
        successMsg(data.message);
        return data;
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateCustomerProfile = createAsyncThunk(
  "auth/updateCustomerProfile",
  async ({ formDataToSend }, { rejectWithValue, dispatch }) => {
    try {
      const customer_id = localStorage.getItem("qctl_customer_id");
      const password = localStorage.getItem("qctl_password");

      formDataToSend.append("customer_id", customer_id);
      formDataToSend.append("password", password);
      formDataToSend.append("type", "Vendor");

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const { data } = await API.post(
        "/customer-profileupdate",
        formDataToSend,
        config
      );

      if (data.status) {
        return data.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const changePassword = createAsyncThunk(
  "auth/changePassword",
  async ({ formdata, setFormData }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const customer_id = localStorage.getItem("qctl_customer_id");
      const password = localStorage.getItem("qctl_password");
      formdata.customer_id = customer_id;
      formdata.password = password;
      formdata.type = "vendor";

      const { data } = await API.post(
        "/customer-changepassword",
        formdata,
        config
      );

      if (data.status) {
        localStorage.setItem("qctl_password", data.password);
        setFormData({
          current_password: "",
          new_password: "",
          confirm_password: "",
        });
        return data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getSettingData = createAsyncThunk(
  "auth/getSettingData",
  async (_, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await API.post("/pagedetail", { page: 0 }, config);

      if (data.status) {
        return data.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);


export const getPassPrintData = createAsyncThunk(
  "auth/getPassPrintData",
  async (_, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await API.post("/pagedetail", { page_id: 9 }, config);

      if (data.status) {
        return data.data;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

