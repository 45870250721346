import React from "react";
import { useSelector } from "react-redux";
import { images } from "../actions/customFn";

const PrintPass = ({ BuyPassDetails }) => {
  const { PassPrintData } = useSelector((state) => state.auth);

  return (
    <>
      <style>
        {`
                          @media print {
                            .hide-from-print {
                              display: none;
                            }
                            .print-only {
                              display: block;
                            }
                          }
                          @media screen {
                            .print-only {
                              display: none;
                            }
                          }
                        `}
      </style>
      <div
        role="article"
        aria-roledescription="email"
        aria-label="email name"
        lang="en"
        style={{
          fontSize: "14px",
          width: "800px",
          margin: "0 auto",
          boxShadow: "0px 30px 40px #000c251a",
          background: "#FAFDFF",
        }}
      >
        <table
          role="presentation"
          align="center"
          border="0"
          cellpadding="0"
          cellspacing="0"
          width="100%"
        >
          <tr style={{ width: "100%" }}>
            <td style={{ padding: "25px 15px" }} colSpan="3">
              <img
                src={images["logo.png"]}
                alt="Logo"
                style={{ width: "160px" }}
              />
            </td>
          </tr>
          <tr>
            <td valign="top" colSpan="3">
              <div
                className="over-mob"
                style={{
                  maxHeight: "300px",
                  margin: "0 auto",
                  textAlign: "center",
                }}
              >
                <img
                  className="reset"
                  src={images["banner.png"]}
                  width="800"
                  height="400"
                  border="0"
                  alt="Slide Image"
                  style={{ verticalAlign: "middle" }}
                />
              </div>
              <table
                role="presentation"
                className="faux-absolute reset"
                align="center"
                border="0"
                cellpadding="0"
                cellspacing="0"
                width="800"
                style={{ position: "relative", opacity: 0.999 }}
              >
                <tr>
                  <td valign="top">
                    <table
                      role="presentation"
                      className="hero-textbox"
                      border="0"
                      cellpadding="0"
                      cellspacing="0"
                      width="92%"
                      bgcolor="#FFFFFE"
                      align="center"
                    >
                      <tr>
                        <td valign="top" style={{ padding: "15px" }}>
                          <table style={{ width: "100%" }}>
                            <tr>
                              <td
                                style={{
                                  width: "65%",
                                  padding: "15px 15px 25px 25px",
                                  backgroundImage: `url(${images["left-green.png"]})`,
                                  backgroundSize: "100% 100%",
                                  backgroundRepeat: "no-repeat",
                                  backgroundPosition: "center",
                                }}
                              >
                                <table>
                                  <tr>
                                    <td colSpan="2">
                                      <h1
                                        style={{
                                          margin: 0,
                                          fontSize: "28px",
                                          color: "#fff",
                                          lineHeight: "42px",
                                        }}
                                      >
                                        {BuyPassDetails?.title}
                                      </h1>
                                      <p
                                        className="left"
                                        style={{
                                          margin: 0,
                                          fontSize: "13px",
                                          color: "#fff",
                                          lineHeight: "18px",
                                          marginBottom: "25px",
                                        }}
                                      >
                                        Good for access to all Q.C.T.L.C land of
                                        the Queen Charlotte Track
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td colSpan="2">
                                      <p
                                        className="left"
                                        style={{
                                          margin: 0,
                                          fontSize: "13px",
                                          color: "#fff",
                                          lineHeight: "18px",
                                        }}
                                      >
                                        Name
                                      </p>
                                      <h1
                                        style={{
                                          margin: 0,
                                          fontSize: "24px",
                                          color: "#fff",
                                          lineHeight: "30px",
                                          wordBreak: "break-all",
                                        }}
                                      >
                                        {BuyPassDetails?.customer_name} for{" "}
                                        {BuyPassDetails?.quantity} People
                                      </h1>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td colSpan="2">
                                      <p
                                        className="left"
                                        style={{
                                          margin: 0,
                                          fontSize: "13px",
                                          color: "#fff",
                                          lineHeight: "18px",
                                        }}
                                      >
                                        Date Of visit
                                      </p>
                                      <h1
                                        style={{
                                          margin: 0,
                                          fontSize: "24px",
                                          color: "#fff",
                                          lineHeight: "30px",
                                        }}
                                      >
                                        {BuyPassDetails?.startdate}
                                      </h1>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td colSpan="2">
                                      <div
                                        style={{
                                          height: "25px",
                                        }}
                                      ></div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td colSpan="2">
                                      <div
                                        style={{
                                          color: "#FFFFFF",
                                          marginBottom: "10px",
                                        }}
                                      >
                                        Please Display this pass so that it is
                                        clearly visible
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td colSpan="2">
                                      <div
                                        style={{
                                          color: "#FFFFFF",
                                          fontWeight: 300,
                                        }}
                                      >
                                        {BuyPassDetails?.description}
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <div
                                        style={{
                                          background: "#fff",
                                          padding: "10px",
                                          width: "140px",
                                          marginTop: "10px",
                                        }}
                                      >
                                        <img
                                          src={images["logo.png"]}
                                          style={{
                                            width: "100%",
                                          }}
                                          alt="Logo"
                                        />
                                      </div>
                                    </td>
                                    <td>
                                      <div
                                        style={{
                                          marginLeft: "auto",
                                          textAlign: "right",
                                          maxWidth: "70%",
                                        }}
                                      >
                                        <img
                                          src={images["right-two-img.png"]}
                                          style={{
                                            width: "80%",
                                          }}
                                          alt="Two Images"
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                              <td
                                style={{
                                  width: "35%",
                                  padding: "15px",
                                  backgroundImage:
                                    `url(${images['right-green.png']})`,
                                  backgroundSize: "100% 100%",
                                  backgroundRepeat: "no-repeat",
                                  backgroundPosition: "center",
                                }}
                              >
                                <div
                                  style={{
                                    margin: "0 auto",
                                    textAlign: "center",
                                  }}
                                >
                                  <img
                                    src={images["code-img.png"]}
                                    style={{ width: "92%" }}
                                    alt="Code"
                                  />
                                  <img
                                    src={images["right-logo.png"]}
                                    style={{ width: "96%" }}
                                    alt="Right Logo"
                                  />
                                </div>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                    <table
                      role="presentation"
                      className="hero-textbox"
                      border="0"
                      cellpadding="0"
                      cellspacing="0"
                      width="92%"
                      bgcolor="#FFFFFE"
                      align="center"
                    >
                      <tr>
                        <td valign="top" style={{ padding: "15px 15px 0" }}>
                          <h2
                            style={{
                              margin: "0px",
                              fontSize: "26px",
                            }}
                          >
                            {PassPrintData?.heading}
                          </h2>
                        </td>
                      </tr>
                      <tr>
                        <td valign="top" style={{ padding: "0 15px 15px" }}>
                          <table style={{ fontSize: "14px" }}>
                            <tr>
                              <td>{PassPrintData?.content}</td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                    <div style={{ height: "40px" }}></div>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr
            style={{
              background: "#fff",
              color: "#000",
              textAlign: "left",
              position: "relative",
              top: "0px",
              fontWeight: 600,
              fontSize: "18px",
              lineHeight: "21px",
            }}
          ></tr>
          <tr
            style={{
              background: "#fff",
              borderTop: "2px solid #02396296",
            }}
          >
            <td colSpan="3">
              <table style={{ width: "100%" }}>
                <tr>
                  <td style={{ padding: "10px 0px 15px 20px" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <img
                         src={images["address-icon.png"]}
                        style={{
                          marginTop: "-5px",
                          marginRight: "5px",
                          width: "30px",
                        }}
                        alt="Address Icon"
                      />
                      {PassPrintData?.address}
                    </div>
                  </td>
                  <td style={{ padding: "10px 0px 15px 20px" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={images["email-icon.png"]}
                        style={{
                          marginTop: "-5px",
                          marginRight: "5px",
                          width: "30px",
                        }}
                        alt="Email Icon"
                      />
                      {PassPrintData?.email}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td
                    colspan="3"
                    style={{
                      padding: "12px 15px",
                      textAlign: "center",
                      fontSize: "12px",
                      lineHeight: "18px",
                      color: "#fff",
                      background: "#023962",
                    }}
                  >
                    {PassPrintData?.copy_right_text}
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </div>
    </>
  );
};

export default PrintPass;
