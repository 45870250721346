import React from 'react';
import './passespaymenttext.scss';
import { images } from "../../../actions/customFn";
import { NavLink } from "react-router-dom";

const PassesPaymentText = ({data}) => {
  return (
    <div className="passpayment-textsection">
    <div
      className="information-bg-mian"
      style={{
        backgroundImage: `url(${images["mountain_img.png"]})`,
      }}
    >
      <div className="container">
        <div className="inner-grid-new">
          <div className="wd-info-50">
            <div className="text-data-grid">
             
              <div className="text-new">
                <h2 className='text-center text-md-left'>{data?.paypal_heading}</h2>
                <p dangerouslySetInnerHTML={{__html:data?.paypal_description}}></p>
                 
              </div>
            </div>
          </div>
          <div className="wd-info-50">
            <div className="text-data-grid">
               
              <div className="text-new">
              <h2 className='text-center text-md-left' >{data?.bank_payment_title}</h2>
              <p dangerouslySetInnerHTML={{__html:data?.bank_payment_description}}></p>
                 
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default PassesPaymentText;