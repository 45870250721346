import React from 'react'
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

const ProtectedRoutes = () => {
    const { isAuthenticated, customerDetails, loading } = useSelector((state) => state.auth);
    const location = useLocation();

    const isAddPassPath = location.pathname.startsWith('/buypass/add');

    if (!isAuthenticated) {
      return <Navigate to="/" />;
    }


    if(isAddPassPath && (customerDetails?.type == "Customer" || customerDetails?.type == "Websitevendor")){
      return <Navigate to="/" />;
    }

    if(location.pathname.startsWith('/checkout') && (customerDetails?.type == "Vendor" || customerDetails?.type == "Websitevendor")){
      return <Navigate to="/" />;
    }

    if((location.pathname.startsWith('/api') || location.pathname.startsWith('/documentation')) && (customerDetails?.type == "Vendor" || customerDetails?.type == "Customer")){
      return <Navigate to="/" />;
    }

    return <Outlet />;
  
}

export default ProtectedRoutes