import React, { useContext, useEffect, useState } from "react";
import "./OrderSummary.scss";
import { images } from "../../../actions/customFn";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { errorMsg } from "../../../services/customeFn";
import StripeElement from "../CheckOutForm/StripeElement";
import ApplePayButton from "../CheckOutForm/___ApplePayButton";
import GooglePay from "../CheckOutForm/GooglePay";
import ApplePay from "../CheckOutForm/ApplePay";
import { Elements } from "@stripe/react-stripe-js";
import { CheckOutPageContext } from "../../../context";
import { loadStripe } from "@stripe/stripe-js";
import PaymentRequestForm from "../CheckOutForm/PaymentRequestForm";
import { useCreateIntentMutation } from "../../../Redux/RTKQ/pageApiSlice";
import { useNavigate } from "react-router-dom";

const OrderSummary = ({
  buyPassDetails,
  purchaseBuypassData,
  onSuccessfulPayment,
  paymentMethod,
  errors,
  formdata,
  validateFormData,
  handleCheckout,
  setFormdata,
}) => {
  const [createIntent] = useCreateIntentMutation();
  const [loading, setLoading] = useState(false);
  const initialOptions = {
    "client-id":
      "AXIZ4j_QdbvNo0OIyhr4Gv_O9GZqmR7aEBmsn9i6IMf-3ti-KfJapXCre3nzA0FACM3dbYsGrQyqnbUs",
    currency: "NZD",
  };
  const navigate = useNavigate();
  const { stripePublishKey } = useContext(CheckOutPageContext);
  const stripePromise = loadStripe(stripePublishKey);

  useEffect(() => {
    validateFormData();
  }, [paymentMethod]);

  var order_id = "";
  const handePaypalIndent = async () => {
    const response = await createIntent({
      ...formdata,
      ...{
        payment_method: "paypal",
      },
    }).unwrap();
    if (response?.status && response?.paypal_order_id) {
      order_id = response?.order_id;
      return response.paypal_order_id; // Return the order ID to PayPal
    } else {
      throw new Error("Order creation failed");
    }
  };

  return (
    <div className="ordersummary_mainsection">
      <h4>Order Summary</h4>
      <div className="order_detailsummery">
        <div className="product_details">
          <div className="product_infoflex">
            <img src={images["product1.png"]} alt="" />
            <div className="right_content">
              <h6>{buyPassDetails?.title}</h6>
              <p>{buyPassDetails?.description}</p>
              <h3>${buyPassDetails?.price}</h3>
            </div>
          </div>
          <div className="info_flexdiv">
            <p>Start date </p>
            <p>
              <span>{purchaseBuypassData?.start_date}</span>
            </p>
          </div>
          <div className="info_flexdiv">
            <p>End date </p>
            <p>
              <span>{buyPassDetails?.end_date}</span>
            </p>
          </div>
          <div className="info_flexdiv">
            <p>Number of people</p>
            <p>
              <span>{purchaseBuypassData?.quantity}</span>
            </p>
          </div>
        </div>

        <div className="discount_totalbottom">
          <div className="info_flexdiv">
            <h6>Total </h6>
            <h6>
              <span>
                ${buyPassDetails?.price * purchaseBuypassData?.quantity}
              </span>
            </h6>
          </div>

          {errors &&
          Object.keys(errors).length == 0 &&
          paymentMethod === "paypal" ? (
            <div className="paypal_button_container">
              <PayPalScriptProvider options={initialOptions}>
                <PayPalButtons
                  fundingSource="paypal"
                  style={{
                    layout: "vertical",
                    shape: "pill",
                    label: "checkout",
                  }}
                  createOrder={async (data, actions) => {
                    return handePaypalIndent()
                      .then((response) => {
                        return response; // Return the order ID to PayPal
                      })
                      .catch((err) => {
                        errorMsg("Failed to create order. Please try again.");
                        console.error(err);
                      });
                  }}
                  onApprove={(data, actions) => {
                    return actions.order
                      .capture()
                      .then((details) => {
                        if (order_id) {
                          navigate(`/thankyou-page/${order_id}`);
                        }
                        // onSuccessfulPayment(details, formdata);
                      })
                      .catch((err) => {
                        errorMsg(err);
                      });
                  }}
                  onCancel={() => {
                    // errorMsg("Payment was cancelled by the user.");
                    // Handle payment cancellation (e.g., notify the user)
                  }}
                  onError={(err) => {
                    if (err.message !== "Detected popup close") {
                      errorMsg(
                        err.message || "An error occurred during payment."
                      );
                    }
                  }}
                />
              </PayPalScriptProvider>
            </div>
          ) : paymentMethod === "stripe" ? (
            <>
              <StripeElement
                setFormdata={setFormdata}
                handleCheckout={handleCheckout}
              />
            </>
          ) : paymentMethod === "apple_pay" ? (
            <>
              <Elements stripe={stripePromise}>
                <ApplePay
                  handleCheckout={handleCheckout}
                  buyPassDetails={buyPassDetails}
                  purchaseBuypassData={purchaseBuypassData}
                  loading={loading}
                  setLoading={setLoading}
                />
              </Elements>
            </>
          ) : paymentMethod === "google_pay" ? (
            <>
              <div className="">
                <GooglePay
                  setFormdata={setFormdata}
                  buyPassDetails={buyPassDetails}
                  purchaseBuypassData={purchaseBuypassData}
                  handleCheckout={handleCheckout}
                />
              </div>
            </>
          ) : (
            <>
              <button
                type="button"
                className="checkout_btn"
                onClick={handleCheckout()}
              >
                Checkout
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderSummary;
