import React from "react";
import "./textcard.scss";
import { NavLink } from "react-router-dom";
const TextCard = ({data}) => {
 
  const historyData = data?.history || [];
  
  return (
    <div className="text-card-main-section">
      <div className="container">
        <div className="section-top-title">
          <h2 className="text-left">{data?.history_heading}</h2>
        </div>
        <div className="wd-info-grid">
          {historyData.map(function (value, index) {
            return (
              <div className="wd-info-33 text-card-data" key={index}>
                <NavLink>
                  <div
                    className="card-bg-img"
                    style={{
                      backgroundImage: `url(${value.icon})`,
                    }}
                  ></div>

                  <div className="text-info">
                    <h3>{value.title}</h3>
                    <p dangerouslySetInnerHTML={{ __html: value.description }}></p>
                  </div>
                </NavLink>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default TextCard;
