import React from "react";
import "./supportplanting.scss";
import { NavLink } from "react-router-dom";

const SupportPlanting = ({ data }) => {
  return (
    <div className="support-planting-main-section">
      <div
        className="section-bg-grid"
        style={{
          backgroundImage: `url(${data?.support_image})`,
        }}
      >
        <div className="container">
          <div className="planting-inner-data wd-info-grid">
            <div className="planting-text-right wd-info-40">
              <div className="title-inner">
                <h3 class="widget-title">{data?.support_heading}</h3>
              </div>

              <div className="text-all-info">
                <div
                  dangerouslySetInnerHTML={{
                    __html: data?.support_description,
                  }}
                />
              </div>
              <div className="btn-inner">
                <NavLink to={"/native-tree-planting"} className="btn-common">
                  Learn More
                </NavLink>
              </div>
            </div>
            <div className="planting-text-left wd-info-60">
              <div className="banner-inner-data">
                <div className="wd-info-50">
                  <div className="title-inner">
                    <h3 class="widget-title">{data?.support_sub_heading1}</h3>
                  </div>

                  <div className="text-all-info">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: data?.support_sub_description1,
                      }}
                    ></p>
                  </div>
                </div>

                <div className="wd-info-50">
                  <div className="title-inner">
                    <h3 class="widget-title">{data?.support_sub_heading2}</h3>
                  </div>

                  <div className="text-all-info">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: data?.support_sub_description2,
                      }}
                    ></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupportPlanting;
