import React, { useContext, useMemo } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import useResponsiveFontSize from "./useResponsiveFontSize";
import { errorMsg } from "../../../services/customeFn";
import { CheckOutPageContext } from "../../../context";
import LoadingBar from "../../LoaderLoding/LoadingBar";
import { useCreateIntentMutation } from "../../../Redux/RTKQ/pageApiSlice";
import { useNavigate } from "react-router-dom";

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    [fontSize]
  );

  return options;
};

const CardForm = ({ setFormdata, handleCheckout }) => {
  const { btnisLoading, setBtnIsLoading, formdata } = useContext(CheckOutPageContext);
  const [createIntent]                              = useCreateIntentMutation();
  const stripe                                      = useStripe();
  const elements                                    = useElements();
  const options                                     = useOptions();
  const navigate                                    = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setBtnIsLoading(true);
    if (!stripe || !elements) {
      return;
    }

    try {
      let sendData = {
        payment_method: "stripe",
      };
      const response = await createIntent({...formdata,...sendData}).unwrap();
      if (response.status) {
        if (response?.client_secret) {
          const clientSecret = response?.client_secret;
          const { paymentIntent, error } = await stripe.confirmCardPayment(
            clientSecret,
            {
              payment_method: {
                card: elements.getElement(CardElement),
              },
            }
          );
          if (paymentIntent) {
            navigate(`/thankyou-page/${response.order_id}`);
          } else {
            errorMsg("something went wrong!");
          }
        }
      }
    } catch (error) {
      console.error("error:", error);
      setBtnIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Card details
        <CardElement
          options={options}
          // onReady={() => {
          //   console.log("CardElement [ready]");
          // }}
          // onChange={(event) => {
          //   console.log("CardElement [change]", event);
          // }}
          // onBlur={() => {
          //   console.log("CardElement [blur]");
          // }}
          // onFocus={() => {
          //   console.log("CardElement [focus]");
          // }}
        />
      </label>
      {btnisLoading ? (
        <button type="button " className="pay_btn" disabled={true}>
          <LoadingBar width={`20px`} height="20px" />
        </button>
      ) : (
        <button type="submit">Pay</button>
      )}
    </form>
  );
};

const StripeElement = ({ setFormdata, handleCheckout }) => {
  const { stripePublishKey } = useContext(CheckOutPageContext);
  const stripePromise = loadStripe(stripePublishKey);
  return (
    <Elements stripe={stripePromise}>
      <div className="stripe_pay">
        <CardForm setFormdata={setFormdata} handleCheckout={handleCheckout} />
      </div>
    </Elements>
  );
};

export default StripeElement;
