import React from "react";
import "./purchaseourpass.scss";
import { images } from "../../../actions/customFn";
import { NavLink } from "react-router-dom";

const PurchaseOurPass = (props) => {
  const {
    heading,
    description,
    subheading,
    our_app_playstore_url,
    our_app_applestore_url,
    our_app_image
  } = props;

  return (
    <div className="container">
      <div className="purchasepass_mainsection">
        <div className="banner-inner-data">
          <div className="wd-info-50">
            <h2 className="text-left">{heading}</h2>

            <p dangerouslySetInnerHTML={{ __html: description }}></p>

            <h4>{subheading}</h4>

            <div className="store_flex">
              <a
                href={our_app_playstore_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={images["googlePlay.png"]} alt="Google Play Store" />
              </a>
              <a
                href={our_app_applestore_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={images["appStore.png"]} alt="Apple App Store" />
              </a>
            </div>
          </div>
          <div className="wd-info-50">
            <div className="purchase_passrightside">
              <img src={our_app_image} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PurchaseOurPass;
