import React from "react";
import ReactLoading from "react-loading";

const LoadingBar = (props) => {
  const { width, height } = props;
  return (
    <div className="circulartriangle_loader">
      <ReactLoading
        type={"spokes"}
        color="#023962"
        height={height != undefined && height != "" ? height : "80px"}
        width={width != undefined && width != "" ? width : "80px"}
      />
    </div>
  );
};

export default LoadingBar;
