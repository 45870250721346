import React from "react";
import "./watertransport.scss";
import { NavLink } from "react-router-dom";

const WaterTransport = ({ data }) => {
  return (
    <div className="watertransport_sectionmain">
      <div className="container">
        <div className="row">
          <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
            <div className="water_transportTitle">
              <h3>{data?.water_heading}</h3>
              <p>{data?.water_sub_heading}</p>
            </div>
          </div>

          <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
            <div className="water_transportContain">
              <img
                className="sow-icon-image"
                src={data?.beachcomber_cruises_image}
                alt=""
              />
              <h5>{data?.beachcomber_cruises} </h5>
              <div className="viewmore_btn">
                <NavLink
                  to={data?.beachcomber_cruises_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View Detail
                </NavLink>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
            <div className="water_transportContain">
              <img
                className="sow-icon-image"
                src={data?.courgar_line_image}
                alt=""
              />
              <h5>{data?.courgar_line} </h5>
              <div className="viewmore_btn">
                <NavLink
                  to={data?.courgar_line_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View Detail
                </NavLink>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
            <div className="water_transportContain">
              <img
                className="sow-icon-image"
                src={data?.arrow_water_taxis_image}
                alt=""
              />
              <h5>{data?.arrow_water_taxis} </h5>
              <div className="viewmore_btn">
                <NavLink
                  to={data?.arrow_water_taxis_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View Detail
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WaterTransport;
