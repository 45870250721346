import { createBrowserRouter, RouterProvider } from "react-router-dom";
import {
  Booking,
  BuyPasses,
  ChangePassword,
  CheckOut,
  ContactUs,
  Coupon,
  EditProfile,
  Home,
  NativeTreePlanting,
  Promotion,
  QCTHistory,
  Thankyoupage,
  TrackEnhancement,
  ViewPassDetail,
  ViewPassList,
  Customer,
  AddCustomer,
  VerifyThankyoupage,
  ResetPassword,
  Survey,
  PageNotFound,
  VendorDashboard,
  Documentation,
  VendorInvoice,
  VendorInvoiceDetails
} from "../pages";

import MainLayout from "../components/MainLayout.js";
import ProtectedRoutes from "./ProtectedRoutes.js";
import WebsitevendorAPI from "../pages/WebsitevendorAPI.js";

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/reset-password/:token",
        element: <ResetPassword />,
      },
      
      {
        path: "/survey",
        element: <Survey />,
      },

      {
        path: "/contact-us",
        element: <ContactUs />,
      },

      {
        path: "/promotion",
        element: <Promotion />,
      },
      
      {
        path: "/track-enhancements",
        element: <TrackEnhancement />,
      },
      
      {
        path: "/qct-history",
        element: <QCTHistory />,
      },

      {
        path: "/buy-passes",
        element: <BuyPasses />,
      },

      {
        path: "/booking",
        element: <Booking />,
      },
      {
        path: "/native-tree-planting",
        element: <NativeTreePlanting />,
      },
      {
        path: "/email-verification/:id?",
        element: <VerifyThankyoupage />,
      },
    ],
  },
  {
    path: "",
    element: <ProtectedRoutes />,
    children: [
      
      {
        path: "/thankyou-page/:id",
        element: <Thankyoupage />,
      },

      {
        path: "/coupon",
        element: <Coupon />,
      },

      {
        path: "/customers",
        element: <Customer />,
      },

      {
        path: "/dashboard",
        element: <VendorDashboard />,
      },

      {
        path: "buypass/add",
        element: <AddCustomer />,
      },

      {
        path: "invoices",
        element: <VendorInvoice />,
      },

      {
        path: "invoices/details/:slug",
        element: <VendorInvoiceDetails />,
      },


      {
        path: "/change-password",
        element: <ChangePassword />,
      },
      {
        path: "/edit-profile",
        element: <EditProfile />,
      },
      {
        path: "/viewpass-list",
        element: <ViewPassList />,
      },
      {
        path: "/viewpass-detail/:id",
        element: <ViewPassDetail />,
      },
      {
        path: "/documentation",
        element: <Documentation />,
      },
      {
        path: "/api/",
        element: <WebsitevendorAPI />,
      },
      {
        path: "/checkout",
        element: <CheckOut />,
      },
    ],
  },
  {
    path: "*",
    element: <PageNotFound />,
  },
 
]);

const Routes = () => {
  
  return <RouterProvider router={router} />;
};

export default Routes;
