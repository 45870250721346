import React, { useEffect, useState } from "react";
import "./WebsitevendorAPI.css";
import { PageTitleHelmet } from "../hooks/PageTitleHelmet";
import { Header, Sidebar } from "../components";
import {
  EyeOutlined,
  EyeInvisibleOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import { Button, Tooltip, message } from "antd";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const WebsitevendorAPI = () => {
  const { customerDetails } = useSelector((state) => state.auth);

  const [publicKey, setpublicKey] = useState("");
  const [secretKey, setsecretKey] = useState("");

  useEffect(() => {
    setpublicKey(customerDetails?.client_id);
    setsecretKey(customerDetails?.client_secret);
  }, []);
  const [showPublicKey, setShowPublicKey] = useState(false);
  const [showSecretKey, setShowSecretKey] = useState(false);

  const copyToClipboard = (key) => {
    if (navigator.clipboard && typeof navigator.clipboard.writeText === 'function') {
      navigator.clipboard.writeText(key)
        .then(() => {
          message.success("Key copied to clipboard!");
        })
        .catch((err) => {
          message.error("Failed to copy the key: " + err);
        });
    } else {
      message.error("Clipboard API not supported.");
    }
  };
  

  return (
    <>
      <PageTitleHelmet />
      <div className="Change-Password-page">
        <div className="header-pages">
          <Header />
        </div>

        <div className="container">
          <div className="slide-bar-grid">
            <div className="left-bar-data">
              <Sidebar />
            </div>
            <div className="right-bar-data">
              <div className="right-side-bg">
                <div className="api-keys-container">
                  <h2>Website Vendor API Keys</h2>

                  {/* Client Key Section */}
                  <div className="key-section">
                    <label htmlFor="public-key" className="key-label">
                      Client ID
                    </label>
                    <div className="key-display">
                      <input
                        type={showPublicKey ? "text" : "password"}
                        id="public-key"
                        value={publicKey}
                        readOnly
                      />
                      <Tooltip title={showPublicKey ? "Hide" : "Show"}>
                        <Button
                          icon={
                            showPublicKey ? (
                              <EyeInvisibleOutlined />
                            ) : (
                              <EyeOutlined />
                            )
                          }
                          onClick={() => setShowPublicKey(!showPublicKey)}
                        />
                      </Tooltip>
                      <Tooltip title="Copy">
                        <Button
                          className="copy-btn"
                          icon={<CopyOutlined />}
                          onClick={() => copyToClipboard(publicKey)}
                        />
                      </Tooltip>
                    </div>
                  </div>

                  {/* Secret Key Section */}
                  <div className="key-section">
                    <label htmlFor="secret-key" className="key-label">
                      Client Secret
                    </label>
                    <div className="key-display">
                      <input
                        type={showSecretKey ? "text" : "password"}
                        id="secret-key"
                        value={secretKey}
                        readOnly
                      />
                      <Tooltip title={showSecretKey ? "Hide" : "Show"}>
                        <Button
                          icon={
                            showSecretKey ? (
                              <EyeInvisibleOutlined />
                            ) : (
                              <EyeOutlined />
                            )
                          }
                          onClick={() => setShowSecretKey(!showSecretKey)}
                        />
                      </Tooltip>
                      <Tooltip title="Copy">
                        <Button
                          className="copy-btn"
                          icon={<CopyOutlined />}
                          onClick={() => copyToClipboard(secretKey)}
                        />
                      </Tooltip>
                    </div>
                  </div>
                </div>

                <div className="documentation_link_container">
                  <Link className="documentation_link_cls" to="/documentation">
                    View Documentation
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WebsitevendorAPI;
