import React, { useEffect, useState } from "react";
import { Header, Sidebar } from "../components";
import { useDispatch, useSelector } from "react-redux";
import { createPurchasePassbyVendor } from "../Redux/Action/authAction";
import Loading from "../services/Loading";
import { getCustomerInfo, successMsg } from "../services/customeFn";
import { useLocation, useNavigate } from "react-router-dom";
import { format } from "date-fns";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import {
  useGetCustomerListMutation,
  useGetPassListMutation,
} from "../Redux/RTKQ/pageApiSlice";
import { PageTitleHelmet } from "../hooks/PageTitleHelmet";

const AddCustomer = () => {
  const [fetchPassList, { data, isLoading: passListLoading }] =
    useGetPassListMutation();
  const { loading, customerDetails } = useSelector((state) => state.auth);
  const { customer_id, password, type } = getCustomerInfo();

  const location = useLocation();
  const { number_of_people, date_of_visit, pass_id } = location.state || {};

  useEffect(() => {
    if (customerDetails?.type == "Customer") {
      navigate("/buy-passes");
    }

    fetchPassList({ customer_id, password, type });
  }, [fetchPassList, customer_id, password]);

  const getPassList = data?.data || [];

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formdata, setFormData] = useState({
    full_name: "",
    email: "",
    date_of_visit: "",
    number_of_people: "",
    trackingpass_id: "",
    tracking_type: "Cycling",
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setFormData(() => ({
      ...formdata,
      date_of_visit: date_of_visit,
      number_of_people: number_of_people,
      trackingpass_id: pass_id,
    }));
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formdata, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = {};

    if (!formdata.full_name) {
      errors.full_name = "Full name is required";
    }

    if (!formdata.date_of_visit) {
      errors.date_of_visit = "Date of visit is required";
    }

    if (!formdata.number_of_people) {
      errors.number_of_people = "Number of people is required";
    }

    if (!formdata.trackingpass_id) {
      errors.trackingpass_id = "pass  is required";
    }

    const emailRegex = /^\S+@\S+\.\S+$/;
    if (!formdata.email) {
      errors.email = "Email is required.";
    } else if (!emailRegex.test(formdata.email)) {
      errors.email = "Invalid email format.";
    }

    setErrors(errors);

    if (Object.keys(errors).length === 0) {
      const formDataToSend = new FormData();
      Object.entries(formdata).forEach(([key, value]) => {
        formDataToSend.append(key, value || "");
      });
      dispatch(createPurchasePassbyVendor({ formDataToSend, setFormData }));
    }
  };

  return (
    <>
      <PageTitleHelmet />
      <div className="Edit-profile-page">
        <div className="header-pages">
          <Header />
        </div>

        <div className="container">
          <div className="slide-bar-grid">
            <div className="left-bar-data">
              <Sidebar />
            </div>

            <div className="right-bar-data">
              <Loading isLoading={loading || passListLoading}>
                <form onSubmit={handleSubmit}>
                  <div className="right-side-bg">
                    <div className="header-top">
                      <h2>Buy Pass</h2>
                    </div>

                    <div className="all-input-inner">
                      <div className="inputall_main">
                        <label htmlFor="">Full Name</label>
                        <input
                          type="text"
                          placeholder="Enter Full Name"
                          name="full_name"
                          value={formdata?.full_name}
                          onChange={handleInputChange}
                        />

                        {errors.full_name && (
                          <div className="error-message">
                            {errors.full_name}
                          </div>
                        )}
                      </div>

                      <div className="inputall_main">
                        <label htmlFor="">Mail Address</label>
                        <input
                          type="text"
                          placeholder="Enter your Mail Address"
                          name="email"
                          value={formdata?.email}
                          onChange={handleInputChange}
                        />
                        {errors.email && (
                          <div className="error-message">{errors.email}</div>
                        )}
                      </div>

                      <div className="inputall_main">
                        <label htmlFor="">Buy Pass</label>

                        <select
                          name="trackingpass_id"
                          id=""
                          value={formdata.trackingpass_id}
                          onChange={handleInputChange}
                        >
                          <option value="">Select pass</option>
                          {getPassList.map((customer) => (
                            <option key={customer.id} value={customer.id}>
                              {customer.title}
                            </option>
                          ))}
                        </select>
                        {errors.trackingpass_id && (
                          <div className="error-message">
                            {errors.trackingpass_id}
                          </div>
                        )}
                      </div>

                      <div
                        className="inputall_main"
                        id={`Input-pass_issue_date-start`}
                      >
                        <label> Date of visit </label>

                        <Flatpickr
                          name="date_of_visit"
                          className="form-control"
                          placeholder="Enter date of visit"
                          value={formdata.date_of_visit}
                          options={{
                            disableMobile: true,
                            minDate: "today", // Disable past dates
                            dateFormat: "Y-m-d",
                          }}
                          onChange={(date) => {
                            handleInputChange({
                              target: {
                                name: "date_of_visit",
                                value: format(date[0], "yyyy-MM-dd"),
                              },
                            });
                          }}
                        />
                        {errors.date_of_visit && (
                          <div className="error-message">
                            {errors.date_of_visit}
                          </div>
                        )}
                        <span className="help-block"></span>
                      </div>

                      <div className="inputall_main" id={`Input-quantity`}>
                        <label>Number of people</label>
                        <input
                          type="number"
                          name="number_of_people"
                          placeholder="Enter number of people"
                          value={formdata.number_of_people}
                          min="1"
                          required=""
                          className="form-control"
                          onChange={handleInputChange}
                        />
                        {errors.number_of_people && (
                          <div className="error-message">
                            {errors.number_of_people}
                          </div>
                        )}
                        <span className="help-block"></span>
                      </div>

                      <div className="inputall_main">
                        <label htmlFor="">Tracking Type</label>
                        <select
                          name="tracking_type"
                          id=""
                          onChange={handleInputChange}
                          value={formdata.tracking_type}
                        >
                          <option value="Cycling">Cycling</option>
                          <option value="Walking">Walking</option>
                        </select>
                        {errors.tracking_type && (
                          <div className="error-message">
                            {errors.tracking_type}
                          </div>
                        )}
                      </div>

                      <div className="password-update-btn">
                        <button to="" className="btn-common">
                          {" "}
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </Loading>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCustomer;
