export const getPageTitle = (path) => {
  switch (path) {
    case "/":
      return "QCTLC  - Queen Charlotte Track Land Cooperative";
    case "/contact-us":
      return "Contact-Us | Queen Charlotte Track Land Cooperative";
    case "/promotion":
      return "Promotion | Queen Charlotte Track Land Cooperative";
    case "/track-enhancements":
      return "Track-Enhancements | Queen Charlotte Track Land Cooperative";
    case "/qct-history":
      return "Qct-History | Queen Charlotte Track Land Cooperative";
    case "/booking":
      return "Booking | Queen Charlotte Track Land Cooperative";
    case "/pricing":
      return "Pricing | Queen Charlotte Track Land Cooperative";
    case "/native-tree-planting":
      return "Native Tree Planting | Queen Charlotte Track Land Cooperative";
    case "/login":
      return "Login | Queen Charlotte Track Land Cooperative";
    case "/coupon":
      return "Coupon | Queen Charlotte Track Land Cooperative";
    case "/customers":
      return "Customers | Queen Charlotte Track Land Cooperative";
    case "/viewpass-list":
      return "View Pass List | Queen Charlotte Track Land Cooperative";
    case "/buy-passes":
      return "Buy Passes | Queen Charlotte Track Land Cooperative";
    case "/change-password":
      return "Change Password | Queen Charlotte Track Land Cooperative";
    case "/invoices":
      return "Invoices | Queen Charlotte Track Land Cooperative";
    case "/buypass/add":
      return "Buypass | Queen Charlotte Track Land Cooperative";
    case "/edit-profile":
      return "Profile | Queen Charlotte Track Land Cooperative";
    default:
      return "Queen Charlotte Track Land Cooperative";
  }
};
