import React from "react";
import Accordion from "react-bootstrap/Accordion";
import Table from "react-bootstrap/Table";
import { Footer, Header } from "../components";
const Documentation = () => {
  return (
    <>
      <Header />
      <div className="documentation">
        <div className="container mb-5">
          <div className="information-container">
            <div className="autorize_btn">
              <button>
                Authorize
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  width="20"
                  height="20"
                  aria-hidden="true"
                  focusable="false"
                >
                  <path d="M15.8 8H14V5.6C14 2.703 12.665 1 10 1 7.334 1 6 2.703 6 5.6V8H4c-.553 0-1 .646-1 1.199V17c0 .549.428 1.139.951 1.307l1.197.387C5.672 18.861 6.55 19 7.1 19h5.8c.549 0 1.428-.139 1.951-.307l1.196-.387c.524-.167.953-.757.953-1.306V9.199C17 8.646 16.352 8 15.8 8zM12 8H8V5.199C8 3.754 8.797 3 10 3c1.203 0 2 .754 2 2.199V8z"></path>
                </svg>
              </button>
            </div>
          </div>
          <div className="api-info">
            <div className="info-box">
              <h3> Purchase Pass</h3>

              <h6 className="my-4">
                Base Url : https://qctlcapp.infosparkles.com/admin
              </h6>
              <Accordion className="custom-acc">
                <Accordion.Item eventKey="0" className="mb-4">
                  <Accordion.Header>
                    <div className="acc-title">
                      <div className="left-side-title">
                        <h4 className="method">POST</h4>
                        <p className="file-name">
                          /api/token <span>Token</span>
                        </p>
                      </div>
                      <div className="acc-btn">
                        <div className="lock">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            class="locked"
                            width="20"
                            height="20"
                            aria-hidden="true"
                            focusable="false"
                          >
                            <path d="M15.8 8H14V5.6C14 2.703 12.665 1 10 1 7.334 1 6 2.703 6 5.6V8H4c-.553 0-1 .646-1 1.199V17c0 .549.428 1.139.951 1.307l1.197.387C5.672 18.861 6.55 19 7.1 19h5.8c.549 0 1.428-.139 1.951-.307l1.196-.387c.524-.167.953-.757.953-1.306V9.199C17 8.646 16.352 8 15.8 8zM12 8H8V5.199C8 3.754 8.797 3 10 3c1.203 0 2 .754 2 2.199V8z"></path>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="para-tabs">
                      <div className="title-sec">
                        <h4 className="tab-title">Parameters</h4>
                      </div>
                      <div className="para-body">
                        <Table>
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Description</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <h4>
                                  client_id{" "}
                                  <span className="req">* required</span>
                                </h4>
                                <p className="type">string</p>
                              </td>
                              <td>
                                <p className="des">
                                  The Client ID is required and must be a unique
                                  string that identifies your application. It is
                                  used during authentication to distinguish your
                                  app from others.
                                </p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <h4>
                                  client_secret{" "}
                                  <span className="req">* required</span>
                                </h4>
                                <p className="type">string</p>
                              </td>
                              <td>
                                <p className="des">
                                  The Client Secret is required and must be a
                                  valid string used for secure authentication.
                                  Ensure it is kept confidential and not shared
                                  publicly.
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </div>

                    <div className="responses-tab">
                      <h4>Responses</h4>
                      <Table>
                        <thead>
                          <tr>
                            <th>Code</th>
                            <th>Description</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="code">200</td>
                            <td>
                              <p className="des">
                                OK: If the requested data was found, the body of
                                the response will contain the relevant
                                information. Otherwise, the response will
                                include an empty array in the body.
                              </p>
                            </td>
                          </tr>

                          <tr>
                            <td className="code">422</td>
                            <td>
                              <ul>
                                <li>The client id field is required.</li>
                                <li>The client secret field is required.</li>
                              </ul>
                            </td>
                          </tr>

                          <tr>
                            <td className="code">401</td>
                            <td>
                              <p className="des">Unauthorized</p>
                            </td>
                          </tr>

                          <tr>
                            <td className="code"> 405</td>
                            <td>
                              <p className="des">
                                Method Not Allowed. Please use POST for this
                                API.
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="1" className="mb-4">
                  <Accordion.Header>
                    <div className="acc-title">
                      <div className="left-side-title">
                        <h4 className="method">POST</h4>
                        <p className="file-name">
                          /api/tracking-pass <span> Tracking Pass </span>
                        </p>
                      </div>
                      <div className="acc-btn">
                        <div className="lock">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            class="locked"
                            width="20"
                            height="20"
                            aria-hidden="true"
                            focusable="false"
                          >
                            <path d="M15.8 8H14V5.6C14 2.703 12.665 1 10 1 7.334 1 6 2.703 6 5.6V8H4c-.553 0-1 .646-1 1.199V17c0 .549.428 1.139.951 1.307l1.197.387C5.672 18.861 6.55 19 7.1 19h5.8c.549 0 1.428-.139 1.951-.307l1.196-.387c.524-.167.953-.757.953-1.306V9.199C17 8.646 16.352 8 15.8 8zM12 8H8V5.199C8 3.754 8.797 3 10 3c1.203 0 2 .754 2 2.199V8z"></path>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="para-tabs">
                      <div className="title-sec">
                        <h4 className="tab-title">Headers</h4>
                      </div>
                      <div className="para-body">
                        <Table>
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Description</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <h4>
                                  Authorization{" "}
                                  <span className="req">* required</span>
                                </h4>
                                <p className="type">string</p>
                              </td>
                              <td>
                                <p className="des">
                                  The Authorization header is required. Format:
                                  Bearer access_token, where "access_token" is
                                  the token provided for authentication.
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </div>

                    <div className="responses-tab">
                      <h4>Responses</h4>
                      <Table>
                        <thead>
                          <tr>
                            <th>Code</th>
                            <th>Description</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="code">200</td>
                            <td>
                              <p className="des">
                                OK: If the requested data was found, the body of
                                the response will contain the relevant
                                information. Otherwise, the response will
                                include an empty array in the body.
                              </p>
                            </td>
                          </tr>

                          <tr>
                            <td className="code"> 405</td>
                            <td>
                              <p className="des">
                                Method Not Allowed. Please use POST for this
                                API.
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    <div className="acc-title">
                      <div className="left-side-title">
                        <h4 className="method">POST</h4>
                        <p className="file-name">
                          /api/purchase-pass
                          <span>Purchase Pass</span>
                        </p>
                      </div>
                      <div className="acc-btn">
                        <div className="lock">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            class="locked"
                            width="20"
                            height="20"
                            aria-hidden="true"
                            focusable="false"
                          >
                            <path d="M15.8 8H14V5.6C14 2.703 12.665 1 10 1 7.334 1 6 2.703 6 5.6V8H4c-.553 0-1 .646-1 1.199V17c0 .549.428 1.139.951 1.307l1.197.387C5.672 18.861 6.55 19 7.1 19h5.8c.549 0 1.428-.139 1.951-.307l1.196-.387c.524-.167.953-.757.953-1.306V9.199C17 8.646 16.352 8 15.8 8zM12 8H8V5.199C8 3.754 8.797 3 10 3c1.203 0 2 .754 2 2.199V8z"></path>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="para-tabs">
                      <div className="title-sec">
                        <h4 className="tab-title">Headers</h4>
                      </div>
                      <div className="para-body">
                        <Table>
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Description</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <h4>
                                  Authorization{" "}
                                  <span className="req">* required</span>
                                </h4>
                                <p className="type">string</p>
                              </td>
                              <td>
                                <p className="des">
                                  The Authorization header is required. Format:
                                  Bearer access_token, where "access_token" is
                                  the token provided for authentication.
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </div>

                    <div className="para-tabs">
                      <div className="title-sec">
                        <h4 className="tab-title">Parameter</h4>
                      </div>
                      <div className="para-body">
                        <Table>
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Description</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <h4>
                                  trackingpass_id{" "}
                                  <span className="req">* required</span>
                                </h4>
                                <p className="type">string</p>
                              </td>
                              <td>
                                <p className="des">
                                  The Tracking Pass ID is required and must be a
                                  valid alphanumeric string.
                                </p>
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <h4>
                                  full_name{" "}
                                  <span className="req">* required</span>
                                </h4>
                                <p className="type">string</p>
                              </td>
                              <td>
                                <p className="des">
                                  The Full Name is required and must include
                                  both first and last names.
                                </p>
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <h4>
                                  Email{" "}
                                  <span className="req">* Required field</span>
                                </h4>
                              </td>
                              <td>
                                <p className="des">
                                  The Email address is required and must be in a
                                  valid format (e.g., user@example.com).
                                </p>
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <h4>
                                  date_of_visit{" "}
                                  <span className="req">* required</span>
                                </h4>

                                <p className="type">string</p>
                              </td>
                              <td>
                                <p className="des">
                                  The Date of Visit is required and must be
                                  entered in the correct format (YYYY-MM-DD).
                                </p>
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <h4>
                                  number_of_people{" "}
                                  <span className="req">* required</span>
                                </h4>

                                <p className="type">string</p>
                              </td>
                              <td>
                                <p className="des">
                                  The Number of People is required and must be a
                                  valid integer representing the total number of
                                  individuals in the group.
                                </p>
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <h4>
                                  tracking_type{" "}
                                  <span className="req">* required</span>
                                </h4>

                                <p className="type">string</p>
                              </td>
                              <td>
                                <p className="des">
                                  The Tracking Type is required and must be
                                  either "Walking" or "Cycle". Please select one
                                  of the available options.
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </div>

                    <div className="responses-tab">
                      <h4>Responses</h4>
                      <Table>
                        <thead>
                          <tr>
                            <th>Code</th>
                            <th>Description</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="code">200</td>
                            <td>
                              <p className="des">
                                OK: If the requested data was found, the body of
                                the response will contain the relevant
                                information. Otherwise, the response will
                                include an empty array in the body.
                              </p>
                            </td>
                          </tr>

                          <tr>
                            <td className="code">401</td>
                            <td>
                              <p className="des">Unauthorized</p>
                            </td>
                          </tr>

                          <tr>
                            <td className="code">422</td>
                            <td>
                              <ul>
                                <li>The tracking pass ID field is required.</li>
                                <li>Tracking pass is not valid.</li>
                                <li>The full name field is required.</li>
                                <li>
                                  The full name must not be greater than 50
                                  characters.
                                </li>
                                <li>The email field is required.</li>
                                <li>
                                  The email must be a valid email address.
                                </li>
                                <li>The date of visit field is required.</li>
                                <li>
                                  The date of visit does not match the format
                                  Y-m-d.
                                </li>
                                <li>The number of people field is required.</li>
                                <li>
                                  The number of people must be an integer.
                                </li>
                                <li>The tracking type field is required.</li>
                                <li>The selected tracking type is invalid.</li>
                              </ul>
                            </td>
                          </tr>

                          <tr>
                            <td className="code"> 405</td>
                            <td>
                              <p className="des">
                                Method Not Allowed. Please use POST for this
                                API.
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Documentation;
