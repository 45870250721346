import React, { useContext } from "react";
import GooglePayButton from "@google-pay/button-react";
import { errorMsg, NumberFormat } from "../../../services/customeFn";
import { CheckOutPageContext } from "../../../context";
import { useCreateIntentMutation } from "../../../Redux/RTKQ/pageApiSlice";
import { useNavigate } from "react-router-dom";

export default function GooglePay(props) {
  const [createIntent] = useCreateIntentMutation();
  const { buyPassDetails, purchaseBuypassData } = props;
  const { setIsLoading, stripePublishKey, formdata } =
    useContext(CheckOutPageContext);
  const navigate = useNavigate();

  const handleSubmit = async (paymentRequest) => {
    setIsLoading(true);
    if (
      paymentRequest &&
      paymentRequest?.paymentMethodData?.tokenizationData?.token != undefined
    ) {
      const token = JSON.parse(
        paymentRequest?.paymentMethodData?.tokenizationData?.token
      )?.id;
      if (token && token != undefined) {
        let sendData = {
          google_pay_token: token,
          payment_method: "google_pay",
        };
        const response = await createIntent({
          ...formdata,
          ...sendData,
        }).unwrap();
        if (response.status) {
          navigate(`/thankyou-page/${response.order_id}`);
        }
      } else {
        errorMsg("something went wrong!");
      }
    } else {
      errorMsg("something went wrong!");
    }
    setIsLoading(false);
  };

  return (
    <div>
      <GooglePayButton
        environment="TEST"
        paymentRequest={{
          apiVersion: 2,
          apiVersionMinor: 0,
          allowedPaymentMethods: [
            {
              type: "CARD",
              parameters: {
                allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
                allowedCardNetworks: ["MASTERCARD", "VISA"],
              },
              tokenizationSpecification: {
                type: "PAYMENT_GATEWAY",
                parameters: {
                  gateway: "stripe",
                  "stripe:version": "2018-10-31",
                  "stripe:publishableKey": stripePublishKey,
                },
              },
            },
          ],
          merchantInfo: {
            merchantId: "TEST",
            merchantName: "YOUR_MERCHANT_NAME",
          },
          transactionInfo: {
            totalPriceStatus: "FINAL",
            totalPriceLabel: "Total",
            totalPrice: NumberFormat(
              buyPassDetails?.price * purchaseBuypassData?.quantity
            ),
            currencyCode: "NZD",
            countryCode: "NZ",
          },
        }}
        onLoadPaymentData={(paymentRequest) => {
          console.log("load payment data", paymentRequest);
          handleSubmit(paymentRequest);
        }}
        existingPaymentMethodRequired="false"
        buttonColor="black"
        buttonType="buy"
      />
    </div>
  );
}
