import React, { useEffect, useState } from "react";
import { Header, Sidebar } from "../components";
import { images } from "../actions/customFn";
import { NavLink, useParams } from "react-router-dom";
import {
  useGetInvoiceDetailsMutation,
  useGetInvoiceListMutation,
} from "../Redux/RTKQ/pageApiSlice";
import { getCustomerInfo } from "../services/customeFn";
import Loading from "../services/Loading";
import { useSelector } from "react-redux";
import { PageTitleHelmet } from "../hooks/PageTitleHelmet";
import { Pagination } from "antd";

const VendorInvoiceDetails = () => {
  const { customer_id, password } = getCustomerInfo();

  const { slug } = useParams();

  const [getInvoiceDetails, { data, isLoading }] =
    useGetInvoiceDetailsMutation();

  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [currentPassList, setCurrentPassList] = useState([]);

  useEffect(() => {
    const fetchInvoiceDetails = async (slug) => {
      const result = await getInvoiceDetails({
        customer_id,
        password,
        search,
        page,
        slug,
      });
      setCurrentPassList(result?.data?.data || currentPassList);
    };

    if (slug) {
      fetchInvoiceDetails(slug);
    }
  }, [search, page, slug]);

  const totalPages = data?.pagination?.last_page || 1;

  return (
    <>
      <PageTitleHelmet />
      <div className="Change-Password-page">
        <div className="header-pages">
          <Header />
        </div>

        <div className="container">
          <div className="slide-bar-grid">
            <div className="left-bar-data">
              <Sidebar />
            </div>
            <div className="right-bar-data">
              <div className="right-side-bg view_pass_list_section">
                {/* Search input */}

                {/* Display loading for new page */}
                <Loading isLoading={isLoading}>
                  {/* Table */}
                  <h4>Invoice Order List </h4>
                  <div className="table-responsive viewpass_tablelist">
                    <table className="table table-striped table-borderless">
                      <thead>
                        <tr>
                          <th scope="col">Booking ID</th>
                          <th scope="col">Pass</th>

                          <th scope="col">Customer</th>

                          <th scope="col">Price</th>
                          <th scope="col">Startdate</th>
                          <th scope="col">Enddate</th>
                          <th scope="col">Number Of People</th>
                          <th scope="col">Status</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody className="current_available_table">
                        {currentPassList.length === 0 ? (
                          <td
                            colSpan="9"
                            className="loadin_not_found_tr text-center"
                          >
                            <h6> No data found</h6>
                          </td>
                        ) : (
                          currentPassList.map((v, i) => (
                            <tr key={i}>
                              <td>{v.order_id}</td>
                              <td>{v.title}</td>

                              <td>{v.customer_name}</td>

                              <td>${v.price}</td>
                              <td>{v.startdate}</td>
                              <td>{v.enddate}</td>
                              <td>{v.quantity}</td>
                              <td>
                                <div
                                  className={
                                    v.ticketstatus === "Activated"
                                      ? "paid_status"
                                      : v.ticketstatus === "Expired"
                                      ? "cancel_status"
                                      : v.ticketstatus === "Pending"
                                      ? "unpaid_status"
                                      : ""
                                  }
                                >
                                  {v.ticketstatus}
                                </div>
                              </td>
                              <td>
                                <NavLink to={`/viewpass-detail/${v.id}`}>
                                  <img src={images["view_eye.png"]} alt="" />
                                </NavLink>
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>

                  {/* Pagination */}
                  <div className="pagination justify-content-end">
                    {totalPages > 1 && (
                      <Pagination
                        current={page}
                        total={totalPages * 10}
                        pageSize={10}
                        onChange={(page) => setPage(page)}
                      />
                    )}
                  </div>
                </Loading>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VendorInvoiceDetails;
