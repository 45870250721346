import React, { useEffect, useRef, useState } from "react";
import { Header, Sidebar } from "../components";
import { useDispatch, useSelector } from "react-redux";
import { updateCustomerProfile } from "../Redux/Action/authAction";
import Loading from "../services/Loading";
import { images } from "../actions/customFn";
import { PageTitleHelmet } from "../hooks/PageTitleHelmet";

const EditProfile = () => {
  const { customerDetails, loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const imageFileRef = useRef(null);

  const [formdata, setFormData] = useState({
    full_name: "",
    phone_number: "",
    image: "",
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (customerDetails) {
      setFormData((pre) => ({
        ...pre,
        full_name: customerDetails?.full_name,
        phone_number: customerDetails?.phone_number,
        image: customerDetails?.image,
      }));
    }
  }, [customerDetails]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formdata, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = {};

    if (!formdata.full_name) {
      errors.full_name = "Full name is required";
    }

    const phoneRegex = /^\d{10}$/;
    if (!formdata.phone_number) {
      errors.phone_number = "Phone number is required.";
    } else if (!phoneRegex.test(formdata.phone_number)) {
      errors.phone_number = "Invalid phone number format.";
    }

    setErrors(errors);

    if (Object.keys(errors).length === 0) {
      const formDataToSend = new FormData();

      Object.entries(formdata).forEach(([key, value]) => {
        formDataToSend.append(key, value || "");
      });

      dispatch(updateCustomerProfile({ formDataToSend }));
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        image: file,
      }));
    }
  };

  return (
    <>
      <PageTitleHelmet />
      <div className="Edit-profile-page">
        <div className="header-pages">
          <Header />
        </div>

        <div className="container">
          <div className="slide-bar-grid">
            <div className="left-bar-data">
              <Sidebar />
            </div>

            <div className="right-bar-data">
              <Loading isLoading={loading}>
                <form onSubmit={handleSubmit}>
                  <div className="right-side-bg">
                    <div className="header-top">
                      <h2>Edit My Profile</h2>
                    </div>

                    <div className="profile_imgsection">
                      <img
                        className="user_image"
                        src={
                          typeof formdata.image === "string"
                            ? formdata.image
                            : URL.createObjectURL(formdata.image)
                        }
                        alt=""
                      />

                      <div className="upload_myimage ">
                        <input
                          type="file"
                          name="image"
                          id="fileInput"
                          accept="image/*"
                          className="d-none"
                          onChange={handleFileChange}
                          ref={imageFileRef}
                        />

                        <button
                          className=""
                          onClick={(e) => {
                            if (imageFileRef.current) {
                              imageFileRef.current.click();
                            } else {
                              console.error("File input ref is not assigned");
                            }
                            e.preventDefault();
                          }}
                        >
                          <img src={images["camera_img.png"]} alt="" />
                        </button>
                      </div>
                    </div>

                    <div className="all-input-inner">
                      <div className="inputall_main">
                        <label htmlFor="">Full Name</label>
                        <input
                          type="text"
                          placeholder="Enter Full Name"
                          name="full_name"
                          value={formdata?.full_name}
                          onChange={handleInputChange}
                        />

                        {errors.full_name && (
                          <div className="error-message">
                            {errors.full_name}
                          </div>
                        )}
                      </div>
                      <div className="inputall_main">
                        <label htmlFor="">Mobile number</label>
                        <input
                          type="text"
                          placeholder="Enter Mobile number"
                          name="phone_number"
                          value={formdata?.phone_number}
                          onChange={handleInputChange}
                        />

                        {errors.phone_number && (
                          <div className="error-message">
                            {errors.phone_number}
                          </div>
                        )}
                      </div>
                      <div className="inputall_main">
                        <label htmlFor="">Mail Address</label>
                        <input
                          type="text"
                          placeholder="Enter your Mail Address"
                          readOnly
                          name="address"
                          value={customerDetails?.email}
                          onChange={handleInputChange}
                        />
                      </div>

                      <div className="password-update-btn">
                        <button to="" className="btn-common">
                          {" "}
                          Save profile
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </Loading>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditProfile;
