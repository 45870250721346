// import React from 'react';

// export default function ApplePayComponent(props) {
//     const {
//         handleCheckout,
//         loading,
//         setLoading
//      } = props;

//     const paymentMethods = [
//         {
//             supportedMethods: "https://apple.com/apple-pay",
//             data: {
//                 version: 20,
//                 merchantIdentifier: "merchant.com.mk.qctlc",
//                 merchantCapabilities: ["supports3DS"],
//                 supportedNetworks: ["amex", "discover", "masterCard", "visa"],
//                 countryCode: "US",
//             },
//         },
//     ];
//     const options = {
//         requestPayerName: false,
//         requestBillingAddress: false,
//         requestPayerEmail: false,
//         requestPayerPhone: false,
//         requestShipping: false,
//         shippingType: "shipping",
//     };
//     const details = {
//         total: {
//             label: "Demo (Card is not charged)",
//             amount: { value: "27.50", currency: "USD" },
//         },
//     };
//     const handleApplePay = async () => {
//         setLoading(true)
//         if (!window.PaymentRequest) {
//             console.error("Payment Request API not supported");
//             return;
//         }
//         const request = new PaymentRequest(paymentMethods, details, options);
//         request.onpaymentmethodchange = (event) => {
//             const paymentDetailsUpdate = {
//                 total: {
//                     label: "Demo (Card is not charged)",
//                     amount: { value: "27.50", currency: "USD" },
//                 },
//             };
//             event.updateWith(paymentDetailsUpdate);
//         };
//         try {
//             const response = await request.show();
//             console.log("Payment response: ", response.details);
//             const status = 'scuccess';
//             await response.complete(status);
//             console.log("response",response)
//             // handleCheckout(response?.details);
//         } catch (error) {
//             console.error("Payment Request error:", error);
//         }
//         setLoading(false);
//     };

//     return (
//         <div className='applePe'>
//             <button
//                 disabled={loading}
//                 className='applepaybutton'
//                 onClick={(e) => {
//                     e.preventDefault();
//                     handleApplePay();
//                 }}
//             >Buy with ApplePay
//             </button>
//             {/* <apple-pay-button buttonstyle="white-outline" type="book" locale="en-US"></apple-pay-button> */}
//         </div>
//     );
// }

import React, { useEffect, useState, useMemo, useContext } from "react";
import {
  useStripe,
  PaymentRequestButtonElement,
} from "@stripe/react-stripe-js";
import { CheckOutPageContext } from "../../../context";
import { NumberFormat } from "../../../services/customeFn";

const ApplePay = (props) => {
  const { purchaseBuypassData, buyPassDetails } = props;
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [canMakePayment, setCanMakePayment] = useState(false);

  const { handleCheckout } = useContext(CheckOutPageContext);

  useEffect(() => {
    if (
      stripe &&
      buyPassDetails?.price != undefined &&
      buyPassDetails?.price != null &&
      purchaseBuypassData?.quantity != undefined &&
      buyPassDetails?.price != null
    ) {
      const pr = stripe.paymentRequest({
        country: "NZ",
        currency: "nzd",
        total: {
          label: "Total",
          amount: buyPassDetails?.price * purchaseBuypassData?.quantity,
        },
        allow:"payment",
        requestPayerName: true,
        requestPayerEmail: true,
      });
      pr.canMakePayment().then((result) => {
        if (result) {
          setCanMakePayment(true);
          setPaymentRequest(pr);
        } else {
          console.warn("Apple Pay is not available on this device or browser.");
        }
      });
      pr.on("paymentmethod", async (event) => {
        let sendData = {
          google_pay_token: event.paymentMethod,
          payment_method: "apple_pay",
        };
        handleCheckout(event, sendData);
        event.complete("success");
      });
    }
  }, [stripe]);

  const options = useMemo(
    () => ({
      paymentRequest,
      style: {
        paymentRequestButton: {
          theme: "dark",
          height: "48px",
          type: "buy",
        },
      },
      allow:"payment"
    }),
    [paymentRequest]
  );

  
  if (!canMakePayment || !paymentRequest) {
    return <p className="not_avai">Apple Pay is not available on this device or browser.</p>;
  }

  return (
    <div>
      <PaymentRequestButtonElement  allow="payment" options={options} />
    </div>
  );
};

export default ApplePay;
