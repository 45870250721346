import React, { useEffect, useRef, useState } from "react";
import "./herosection.scss";
import { images } from "../../../actions/customFn";
// import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "animate.css";
// import "swiper/css";
// import "swiper/css/effect-fade";
// import "swiper/css/navigation";
// import "swiper/css/pagination";
// import './styles.css';
import $ from "jquery";
import OwlCarousel from "react-owl-carousel";
// import required modules
// import { EffectFade, Navigation, Pagination } from "swiper/modules";

const experience = {
  loop: true,
  margin: 0,
  nav: true,
  dots: true,
  autoplay: true,
  autoplaySpeed: 1000,
  navText: [
    '<svg xmlns="http://www.w3.org/2000/svg" width="8" height="16" viewBox="0 0 8 16">  <path id="fi-rr-angle-small-down" d="M15.665,7.673a1.163,1.163,0,0,1-1.616,0L8.808,2.558a1.188,1.188,0,0,0-1.616,0L1.951,7.673a1.163,1.163,0,0,1-1.616,0,1.1,1.1,0,0,1,0-1.578L5.575.98a3.488,3.488,0,0,1,4.849,0L15.665,6.1a1.1,1.1,0,0,1,0,1.577Z" transform="translate(8) rotate(90)"></path></svg>',
    '<svg xmlns="http://www.w3.org/2000/svg" width="8" height="16" viewBox="0 0 8 16">  <path id="fi-rr-angle-small-down" d="M15.665,7.673a1.163,1.163,0,0,1-1.616,0L8.808,2.558a1.188,1.188,0,0,0-1.616,0L1.951,7.673a1.163,1.163,0,0,1-1.616,0,1.1,1.1,0,0,1,0-1.578L5.575.98a3.488,3.488,0,0,1,4.849,0L15.665,6.1a1.1,1.1,0,0,1,0,1.577Z" transform="translate(8) rotate(90)"></path></svg>',
  ],
  animateIn: "fadeIn",
  animateOut: "fadeOut",
  // onTranslated: () => {
  //   document.querySelectorAll('.post_title').forEach(item => {
  //     item.classList.remove('top-to-bottom');
  //     void item.offsetWidth; // trigger reflow to restart the animation
  //     item.classList.add('top-to-bottom');
  //   });
  // },
  responsive: {
    0: {
      items: 1,
    },
    375: {
      items: 1,
    },
  },
};

const HeroSection = ({ data }) => {


  const my_dynPad = () => {
    const container = document.getElementById("conatiner_id");
    if (container) {

      const marLeft = parseInt(localStorage.getItem("qctlc_marginLeft")) || 0;
      const pad_left = parseInt(getComputedStyle(container).paddingLeft);
      const dyn_space = `${marLeft + pad_left}px`;
      document.querySelectorAll(".dynamic_left").forEach(el => {
        el.style.paddingLeft = dyn_space;
      });

      document.querySelectorAll(".dynamic_leftmargin").forEach(el => {
        el.style.marginLeft = dyn_space;
      });

    }
  };


 
  useEffect(() => {
    my_dynPad()
    window.addEventListener("resize", my_dynPad);

    return () => {
     
      window.removeEventListener("resize", my_dynPad);
    };
  }, []);



 

  return (

    <>
      <div className="herosection_main">
        <>
          <OwlCarousel {...experience} className="" nav margin={30}>
            {data &&
              data.map((value, index) => {
                return (
                  <div className="item " key={index}>
                    <div
                      className="swiper_mainimg dynamic_leftmargin "
                      style={{ backgroundImage: `url(${value.web_image})` }}
                    ></div>

                    <div className="heroslider_content ">
                      <div className="dynamic_left">
                        <h3 class="post_title top-to-bottom">{value.title} </h3>

                        <p class="post_shortpara ">{value.description}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
          </OwlCarousel>
        </>
      </div>
    </>

  );
};

export default HeroSection;
