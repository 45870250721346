import React, { useEffect, useState } from "react";
import { Header, Sidebar } from "../components";
import { images } from "../actions/customFn";
import { NavLink } from "react-router-dom";
import { useGetInvoiceListMutation } from "../Redux/RTKQ/pageApiSlice";
import { errorMsg, getCustomerInfo } from "../services/customeFn";
import Loading from "../services/Loading";
import { useSelector } from "react-redux";
import { PageTitleHelmet } from "../hooks/PageTitleHelmet";
import { Pagination } from "antd";
import API from "../services/API";
import { DownloadOutlined } from "@ant-design/icons";
import { ButtonGroup } from "react-bootstrap";

const VendorInvoice = () => {
  const { customer_id, password } = getCustomerInfo();

  const { customerDetails  } = useSelector(
    (state) => state.auth
  );

  const [getInvoiceList, { data, isLoading }] = useGetInvoiceListMutation();

  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [currentPassList, setCurrentPassList] = useState([]);

  useEffect(() => {
    const fetchInvoiceList = async () => {
      const result = await getInvoiceList({
        customer_id,
        password,
        search,
        page,
      });
      setCurrentPassList(result?.data?.data || currentPassList);
    };

    fetchInvoiceList();
  }, [search, page]);

  const totalPages = data?.pagination?.last_page || 1;

  const handleDownload = async (slug) => {
    try {
      const response = await API({
        url: `/invoice/download`,
        method: "POST",
        responseType: "blob", // Ensure we receive binary data for the PDF
        data: {
          slug: slug,
          customer_id,
          password,
        },
      });

      // Create a link element to download the file
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `invoice_${customerDetails?.full_name}_${slug}.pdf`); // Set filename
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      // Default error message
      let Message = "Failed to download PDF.";

      // Check for network errors or server response errors
      if (error.response) {
        // The request was made, but the server responded with a status code
        // that falls out of the range of 2xx
        switch (error.response.status) {
          case 400:
            Message = "Invalid request. Please check the entered data.";
            break;
          case 401:
            Message = "Unauthorized. Please check your credentials.";
            break;
          case 404:
            Message = "Invoice not found.";
            break;
          case 500:
            Message = "Server error. Please try again later.";
            break;
          default:
            Message = "Unexpected error occurred. Please try again.";
        }
      } else if (error.request) {
        // The request was made but no response was received
        Message =
          "No response from server. Please check your internet connection.";
      } else {
        // Something happened in setting up the request that triggered an Error
        Message = "Request error: " + error.message;
      }

      errorMsg(Message);
    }
  };

  return (
    <>
      <PageTitleHelmet />
      <div className="Change-Password-page">
        <div className="header-pages">
          <Header />
        </div>

        <div className="container">
          <div className="slide-bar-grid">
            <div className="left-bar-data">
              <Sidebar />
            </div>
            <div className="right-bar-data">
              <div className="right-side-bg view_pass_list_section">
                {/* Search input */}

                {/* Display loading for new page */}
                <Loading isLoading={isLoading}>
                  {/* Table */}
                  <h4>Invoice List</h4>
                  <div className="table-responsive viewpass_tablelist">
                    <table className="table table-striped table-borderless">
                      <thead>
                        <tr>
                          <th scope="col">Date</th>
                          <th scope="col"> Day Passes Count</th>
                          <th scope="col"> Multi-Day Passes Count</th>
                          <th scope="col"> Sesson Passes Count</th>
                          <th scope="col"> Bash Passes Count</th>
                          <th scope="col">Total Orders</th>
                          <th scope="col">Price</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody className="current_available_table">
                        {currentPassList.length === 0 ? (
                          <td
                            colSpan="9"
                            className="loadin_not_found_tr text-center"
                          >
                            <h6> No data found</h6>
                          </td>
                        ) : (
                          currentPassList.map((v, i) => (
                            <tr key={i}>
                              <td>
                                {" "}
                                {v?.month_char} {v?.year}
                              </td>
                              <td>{v?.day_passes_count}</td>
                              <td>{v?.multiday_passes_count}</td>
                              <td>{v?.sesson_passes_count}</td>
                              <td>{v?.bach_passes_count}</td>
                              <td>{v?.total_orders}</td>
                              <td>${v?.trackingpass_price_sum}</td>

                              <td>
                                <NavLink
                                  to={`/invoices/details/${v?.slug}`}
                                  className="mr-2"
                                >
                                  <img src={images["view_eye.png"]} alt="" />
                                </NavLink>

                                <NavLink
                                  onClick={() => handleDownload(v?.slug)}
                                >
                                  <img src={images["downlaod.png"]} alt="" />
                                </NavLink>
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>

                  {/* Pagination */}
                  <div className="pagination justify-content-end">
                    {totalPages > 1 && (
                      <Pagination
                        current={page}
                        total={totalPages * 10}
                        pageSize={10}
                        onChange={(page) => setPage(page)}
                      />
                    )}
                  </div>
                </Loading>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VendorInvoice;
