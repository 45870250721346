import React, { useEffect, useRef, useState } from "react";
import { Header, LoadingBar, Sidebar } from "../components";
import { images } from "../actions/customFn";
import { useGetPurchasedPassDetailsMutation } from "../Redux/RTKQ/pageApiSlice";
import { errorMsg, getCustomerInfo } from "../services/customeFn";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import PrintPass from "../components/PrintPass";
import { DownloadOutlined, PrinterOutlined } from "@ant-design/icons";
import { Button } from "antd";
import API from "../services/API";
import Loading from "../services/Loading";

import { LoadingOutlined } from "@ant-design/icons";

const ViewPassDetail = () => {
  const { id } = useParams();
  const { isAuthenticated, customerDetails, PassPrintData } = useSelector(
    (state) => state.auth
  );
  const { customer_id, password, type } = getCustomerInfo();
  const [getPurchasedPassDetails, { data, error: buyPasserror, isLoading }] =
    useGetPurchasedPassDetailsMutation();

  useEffect(() => {
    getPurchasedPassDetails({ customer_id, password, id });
  }, [id]);

  const BuyPassDetails = data?.data || null;

  const printRef = useRef();

  const [isDownloading, setIsDownloading] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: "Pass Details",
    onBeforePrint: () => setIsPrinting(true), // Show loader before print starts
    onAfterPrint: () => setIsPrinting(false),
  });

  const handleDownload = async (id) => {
    setIsDownloading(true);
    try {
      const response = await API({
        url: `/downloadPass`,
        method: "POST",
        responseType: "blob", // Ensuring the response is treated as binary data
        data: {
          order_id: id,
          customer_id,
          password,
        },
      });

      // Create a link element to download the file
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: "application/pdf" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${BuyPassDetails?.title}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      let Message = "Failed to download PDF.";

      if (error.response) {
        switch (error.response.status) {
          case 400:
            Message = "Invalid request. Please check the entered data.";
            break;
          case 401:
            Message = "Unauthorized. Please check your credentials.";
            break;
          case 404:
            Message = "Invoice not found.";
            break;
          case 500:
            Message = "Server error. Please try again later.";
            break;
          default:
            Message = "Unexpected error occurred. Please try again.";
        }
      } else if (error.request) {
        Message =
          "No response from server. Please check your internet connection.";
      } else {
        Message = "Request error: " + error.message;
      }

      errorMsg(Message);
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <div className="Change-Password-page">
      <div className="header-pages">
        <Header />
      </div>

      <div className="container">
        <div className="slide-bar-grid">
          <div className="left-bar-data">
            <Sidebar />
          </div>
          <div className="right-bar-data">
            {isLoading || !BuyPassDetails ? (
              <LoadingBar />
            ) : (
              <div className="right-side-bg right-passdetail-space">
                <div className="viewdetailpassflex">
                  <div className="passdetail_leftcontent">
                    <div className="header-top">
                      <h2>Pass Details</h2>
                    </div>

                    {/* Hidden Print Content */}
                    <div ref={printRef} className="print-only">
                      <PrintPass BuyPassDetails={BuyPassDetails} />
                    </div>

                    <div className="viewpass_detailsection">
                      <div className="header-top">
                        <h2>{BuyPassDetails?.title}</h2>
                      </div>
                      <p>{BuyPassDetails?.description}</p>

                      <div className="detail_pricelists">
                        <div className="row">
                          <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-6">
                            <p>Booking ID : </p>
                          </div>
                          <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-6">
                            <p>
                              <span>{BuyPassDetails?.order_id}</span>
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="detail_pricelists">
                        {BuyPassDetails?.customer_name && (
                          <div className="row">
                            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-6">
                              <p>Customer</p>
                            </div>
                            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-6">
                              <p>
                                <span>{BuyPassDetails?.customer_name}</span>
                              </p>
                            </div>
                          </div>
                        )}

                        {BuyPassDetails?.email &&
                          customerDetails?.type == "Vendor" && (
                            <div className="row">
                              <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-6">
                                <p>Email</p>
                              </div>
                              <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-6">
                                <p>
                                  <span>{BuyPassDetails?.email}</span>
                                </p>
                              </div>
                            </div>
                          )}

                        <div className="row">
                          <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-6">
                            <p>Price</p>
                          </div>
                          <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-6">
                            <p>
                              <span>${BuyPassDetails?.price}</span>
                            </p>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-6">
                            <p>Start date </p>
                          </div>
                          <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-6">
                            <p>
                              <span>{BuyPassDetails?.startdate}</span>
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-6">
                            <p>End date </p>
                          </div>
                          <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-6">
                            <p>
                              <span>{BuyPassDetails?.enddate}</span>
                            </p>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-6">
                            <p>Tracking Type </p>
                          </div>
                          <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-6">
                            <p>
                              <span>{BuyPassDetails?.tracking_type}</span>
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-6">
                            <p>Number Of People </p>
                          </div>
                          <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-6">
                            <p>
                              <span>{BuyPassDetails?.quantity}</span>
                            </p>
                          </div>
                        </div>
                        {customerDetails?.type === "Customer" && (
                          <>
                            <div className="row">
                              <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-6">
                                <p>Payment method</p>
                              </div>
                              <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-6">
                                {(() => {
                                  switch (BuyPassDetails?.paymentmethod) {
                                    case "stripe":
                                      return (
                                        <>
                                          <img
                                            src={images["visa_payimg.png"]}
                                            alt=""
                                          />
                                        </>
                                      );
                                    case "Paypal":
                                      return (
                                        <>
                                          <img
                                            src={images["paypal1.png"]}
                                            alt=""
                                          />
                                        </>
                                      );
                                    case "google_pay":
                                      return (
                                        <>
                                          <img
                                            src={images["google-pay.png"]}
                                            alt=""
                                          />
                                        </>
                                      );
                                    default:
                                      return "";
                                  }
                                })()}
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-6">
                                <p>Payment </p>
                              </div>
                              <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-6">
                                {(() => {
                                  switch (BuyPassDetails?.payment_status) {
                                    case "Success":
                                      return (
                                        <div className="success_tag">
                                          Success
                                        </div>
                                      );
                                    case "Pending":
                                      return (
                                        <div className="pending_tag">
                                          Pending
                                        </div>
                                      );
                                    case "Canceled":
                                      return (
                                        <div className="canceled_tag">
                                          Canceled
                                        </div>
                                      );
                                    case "Failed":
                                      return (
                                        <div className="failed_tag">Failed</div>
                                      );
                                    case "Processing":
                                      return (
                                        <div className="processing_tag">
                                          Processing
                                        </div>
                                      );
                                    default:
                                      return "";
                                  }
                                })()}
                              </div>
                            </div>
                          </>
                        )}

                        <div className="d-flex ">
                          <Button
                            type="primary"
                            icon={
                              isDownloading ? (
                                <LoadingOutlined />
                              ) : (
                                <DownloadOutlined />
                              )
                            }
                            onClick={() => handleDownload(BuyPassDetails?.id)}
                            className="print_btn_cls mr-2 "
                          >
                            {isDownloading ? "Downloading..." : "Download Pass"}
                          </Button>

                          <Button
                            type="primary"
                            icon={
                              isPrinting ? (
                                <LoadingOutlined />
                              ) : (
                                <PrinterOutlined />
                              )
                            }
                            onClick={handlePrint}
                            className="print_btn_cls  "
                          >
                            Print Pass Details
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="rightpass_content">
                    <img src={images["pass-detail.png"]} alt="" />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewPassDetail;
