import React from "react";
import "./safetyinfo.scss";
import { images } from "../../../actions/customFn";

const SafetyInfo = ({ data }) => {
  return (
    <div className="safetyinfo_mainsection">
      <div className="container">
        <div className="safetyInfo_title">
          <h3>{data?.importand_heading}</h3>
          <p>{data?.importand_sub_heading}</p>
          <p>
            <img src={images["safety_icon1.png"]} alt="" />
            <img src={images["safety_icon2.png"]} alt="" />
            <img src={images["safety_icon3.png"]} alt="" />
            <img src={images["safety_icon4.png"]} alt="" />
            <img src={images["safety_icon5.png"]} alt="" />
          </p>
        </div>

        <div className="row">
          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
            <div className="enjoyTrack_contain">
              <img src={data?.title_image1} alt="" />
              <h5>{data?.title1}</h5>
              <div
                dangerouslySetInnerHTML={{ __html: data?.title_description1 }}
              />
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
            <div className="enjoyTrack_contain">
              <img src={data?.title_image2} alt="" />
              <h5>{data?.title2}</h5>
              <div
                dangerouslySetInnerHTML={{ __html: data?.title_description2 }}
              />
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
            <div className="enjoyTrack_contain">
              <img src={data?.title_image3} alt="" />
              <h5>{data?.title3}</h5>
              <div
                dangerouslySetInnerHTML={{ __html: data?.title_description3 }}
              />
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
            <div className="enjoyTrack_contain">
              <img src={data?.title_image4} alt="" />
              <h5>{data?.title4}</h5>
              <div
                dangerouslySetInnerHTML={{ __html: data?.title_description4 }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SafetyInfo;
