import React from "react";
import { images } from "../../../actions/customFn";
import { NavLink, useNavigate } from "react-router-dom";
import "./sidebar.scss";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../Redux/Slice/authSlice";
import { successMsg } from "../../../services/customeFn";
const Sidebar = () => {
  const { isAuthenticated, customerDetails } = useSelector(
    (state) => state.auth
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    successMsg("Logout successfully");
    dispatch(logout());
    navigate("/");
  };

  return (
    <div className="side-bar-new-section">
      <div className="section-top-data">
        <div className="image-user">
          <img src={customerDetails?.image} alt="" loading="lazy" />
        </div>
        <div className="user-data">
          <p>{customerDetails?.full_name}</p>
          <h2>{customerDetails?.phone_number}</h2>
          <h3>{customerDetails?.email}</h3>
        </div>

        <div className="btn-edit-pro">
          <NavLink to="/edit-profile">
            <img src={images["icon-edit.svg"]} alt="" loading="lazy" />
          </NavLink>
        </div>
        
      </div>

      <div className="list-menu-data">
        <ul>
          <li>
            <NavLink to="/dashboard">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path d="M3 13H11V21H3V13Z" fill="#505050" />
                  <path d="M13 3H21V10H13V3Z" fill="#505050" />
                  <path d="M13 13H21V21H13V13Z" fill="#505050" />
                  <path d="M3 3H11V10H3V3Z" fill="#505050" />
                </svg>
              </span>
              Dashbaord
            </NavLink>
          </li>

          <li>
            <NavLink to="/coupon">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="15"
                  viewBox="0 0 21 15"
                  fill="none"
                >
                  <path
                    d="M19.0847 0H18.0802H16.0713H4.01783H2.00891H1.00446C0.448992 0 0 0.448992 0 1.00446V2.00891V3.95555V5.02228H0.89698C1.89742 5.02228 2.82554 5.70632 2.98625 6.6937C3.19116 7.95228 2.22487 9.04011 1.00446 9.04011H0V10.1068V12.0535V13.0579C0 13.6134 0.448992 14.0624 1.00446 14.0624H2.00891H4.01783H16.0713H18.0802H19.0847C19.6401 14.0624 20.0891 13.6134 20.0891 13.0579V12.0535V10.1068V9.04011C20.0891 9.04011 19.6401 9.04011 19.0847 9.04011C17.8643 9.04011 16.898 7.95228 17.1029 6.6937C17.2636 5.70632 18.1917 5.02228 19.1922 5.02228H20.0891V3.95555V2.00891V1.00446C20.0891 0.448992 19.6401 0 19.0847 0ZM9.04011 12.0535H7.0312V10.0446H9.04011V12.0535ZM9.04011 8.03566H7.0312V6.02674H9.04011V8.03566ZM9.04011 4.01783H7.0312V2.00891H9.04011V4.01783Z"
                    fill="#505050"
                  />
                </svg>
              </span>
              Coupons
            </NavLink>
          </li>

          {customerDetails?.type == "Websitevendor" && (
            <>
              <li>
                <NavLink to="/api">
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M16 5.5C16 8.53757 13.5376 11 10.5 11H7V13H5V15L4 16H0V12L5.16351 6.83649C5.0567 6.40863 5 5.96094 5 5.5C5 2.46243 7.46243 0 10.5 0C13.5376 0 16 2.46243 16 5.5ZM13 4C13 4.55228 12.5523 5 12 5C11.4477 5 11 4.55228 11 4C11 3.44772 11.4477 3 12 3C12.5523 3 13 3.44772 13 4Z"
                        fill="#505050"
                      />
                    </svg>
                  </span>
                  Api
                </NavLink>
              </li>

              <li>
                <NavLink to="/invoices">
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path
                        d="M19 3h-2V1H7v2H5a2 2 0 00-2 2v16a2 2 0 002 2h14a2 2 0 002-2V5a2 2 0 00-2-2zM7 3h10v2H7V3zm12 16H5V7h14v12zM7 9h6v2H7V9zm0 4h6v2H7v-2zm8 2h2v2h-2v-2z"
                        fill="#505050"
                      />
                    </svg>
                  </span>
                  Invoices
                </NavLink>
              </li>
            </>
          )}

          {customerDetails?.type == "Vendor" && (
            <>
              <li>
                <NavLink to="/buypass/add">
                  <span>
                    <svg
                      class="w-6 h-6 text-gray-800 dark:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M12 14a3 3 0 0 1 3-3h4a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-4a3 3 0 0 1-3-3Zm3-1a1 1 0 1 0 0 2h4v-2h-4Z"
                        clip-rule="evenodd"
                      />
                      <path
                        fill-rule="evenodd"
                        d="M12.293 3.293a1 1 0 0 1 1.414 0L16.414 6h-2.828l-1.293-1.293a1 1 0 0 1 0-1.414ZM12.414 6 9.707 3.293a1 1 0 0 0-1.414 0L5.586 6h6.828ZM4.586 7l-.056.055A2 2 0 0 0 3 9v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2h-4a5 5 0 0 1 0-10h4a2 2 0 0 0-1.53-1.945L17.414 7H4.586Z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </span>
                  Buy Pass
                </NavLink>
              </li>

              <li>
                <NavLink to="/invoices">
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path
                        d="M19 3h-2V1H7v2H5a2 2 0 00-2 2v16a2 2 0 002 2h14a2 2 0 002-2V5a2 2 0 00-2-2zM7 3h10v2H7V3zm12 16H5V7h14v12zM7 9h6v2H7V9zm0 4h6v2H7v-2zm8 2h2v2h-2v-2z"
                        fill="#505050"
                      />
                    </svg>
                  </span>
                  Invoices
                </NavLink>
              </li>
            </>
          )}


        {customerDetails?.type != "Websitevendor" && (
          <li>
            <NavLink to="/viewpass-list">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="17"
                  viewBox="0 0 21 17"
                  fill="none"
                >
                  <path
                    d="M19.3201 0.653809H2.52076C1.59329 0.653809 0.84082 1.40628 0.84082 2.33375V14.6533C0.84082 15.5807 1.59329 16.3332 2.52076 16.3332H19.3201C20.2476 16.3332 21.0001 15.5807 21.0001 14.6533V2.33375C21.0001 1.40628 20.2476 0.653809 19.3201 0.653809ZM7.00059 4.01368C8.23604 4.01368 9.24051 5.01814 9.24051 6.2536C9.24051 7.48905 8.23604 8.49351 7.00059 8.49351C5.76514 8.49351 4.76067 7.48905 4.76067 6.2536C4.76067 5.01814 5.76514 4.01368 7.00059 4.01368ZM10.9204 12.3014C10.9204 12.6724 10.5705 12.9733 10.1365 12.9733H3.86471C3.43072 12.9733 3.08074 12.6724 3.08074 12.3014V11.6294C3.08074 10.5164 4.1342 9.61347 5.43265 9.61347H5.60764C6.03813 9.79196 6.50711 9.89346 7.00059 9.89346C7.49407 9.89346 7.96655 9.79196 8.39354 9.61347H8.56853C9.86698 9.61347 10.9204 10.5164 10.9204 11.6294V12.3014ZM18.7601 10.4534C18.7601 10.6074 18.6342 10.7334 18.4802 10.7334H13.4403C13.2864 10.7334 13.1604 10.6074 13.1604 10.4534V9.89346C13.1604 9.73947 13.2864 9.61347 13.4403 9.61347H18.4802C18.6342 9.61347 18.7601 9.73947 18.7601 9.89346V10.4534ZM18.7601 8.21352C18.7601 8.36752 18.6342 8.49351 18.4802 8.49351H13.4403C13.2864 8.49351 13.1604 8.36752 13.1604 8.21352V7.65354C13.1604 7.49955 13.2864 7.37356 13.4403 7.37356H18.4802C18.6342 7.37356 18.7601 7.49955 18.7601 7.65354V8.21352ZM18.7601 5.97361C18.7601 6.1276 18.6342 6.2536 18.4802 6.2536H13.4403C13.2864 6.2536 13.1604 6.1276 13.1604 5.97361V5.41363C13.1604 5.25963 13.2864 5.13364 13.4403 5.13364H18.4802C18.6342 5.13364 18.7601 5.25963 18.7601 5.41363V5.97361Z"
                    fill="#505050"
                  />
                </svg>
              </span>
              Purchase History
            </NavLink>
          </li>
        )}

          <li>
            <NavLink to="/change-password">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="19"
                  viewBox="0 0 16 19"
                  fill="none"
                >
                  <path
                    d="M13.3333 6.22222H15.1111C15.3469 6.22222 15.573 6.31587 15.7397 6.48257C15.9064 6.64927 16 6.87536 16 7.11111V17.7778C16 18.0135 15.9064 18.2396 15.7397 18.4063C15.573 18.573 15.3469 18.6667 15.1111 18.6667H0.888889C0.653141 18.6667 0.427048 18.573 0.260349 18.4063C0.0936505 18.2396 0 18.0135 0 17.7778V7.11111C0 6.87536 0.0936505 6.64927 0.260349 6.48257C0.427048 6.31587 0.653141 6.22222 0.888889 6.22222H2.66667V5.33333C2.66667 3.91885 3.22857 2.56229 4.22876 1.5621C5.22896 0.561903 6.58551 0 8 0C9.41449 0 10.771 0.561903 11.7712 1.5621C12.7714 2.56229 13.3333 3.91885 13.3333 5.33333V6.22222ZM11.5556 6.22222V5.33333C11.5556 4.39034 11.181 3.48597 10.5142 2.81918C9.84736 2.15238 8.94299 1.77778 8 1.77778C7.05701 1.77778 6.15264 2.15238 5.48584 2.81918C4.81905 3.48597 4.44444 4.39034 4.44444 5.33333V6.22222H11.5556ZM7.11111 11.5556V13.3333H8.88889V11.5556H7.11111ZM3.55556 11.5556V13.3333H5.33333V11.5556H3.55556ZM10.6667 11.5556V13.3333H12.4444V11.5556H10.6667Z"
                    fill="#505050"
                  />
                </svg>
              </span>
              Change password
            </NavLink>
          </li>

          <li>
            <NavLink to="/" onClick={handleLogout}>
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="18"
                  viewBox="0 0 17 18"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2.68424 0C1.97234 0 1.28959 0.284464 0.786196 0.790812C0.282803 1.29716 0 1.98392 0 2.7V15.3C0 16.0161 0.282803 16.7028 0.786196 17.2092C1.28959 17.7155 1.97234 18 2.68424 18H8.05272C8.76463 18 9.44737 17.7155 9.95077 17.2092C10.4542 16.7028 10.737 16.0161 10.737 15.3V2.7C10.737 1.98392 10.4542 1.29716 9.95077 0.790812C9.44737 0.284464 8.76463 0 8.05272 0H2.68424ZM11.8939 4.7637C12.0617 4.59498 12.2892 4.50019 12.5265 4.50019C12.7637 4.50019 12.9913 4.59498 13.159 4.7637L16.738 8.3637C16.9058 8.53247 17 8.76135 17 9C17 9.23865 16.9058 9.46752 16.738 9.6363L13.159 13.2363C12.9903 13.4002 12.7643 13.491 12.5297 13.4889C12.2951 13.4869 12.0707 13.3922 11.9048 13.2253C11.7389 13.0585 11.6448 12.8327 11.6427 12.5968C11.6407 12.3608 11.7309 12.1334 11.8939 11.9637L13.9455 9.9H6.26323C6.02593 9.9 5.79834 9.80518 5.63055 9.6364C5.46275 9.46761 5.36848 9.23869 5.36848 9C5.36848 8.7613 5.46275 8.53239 5.63055 8.3636C5.79834 8.19482 6.02593 8.1 6.26323 8.1H13.9455L11.8939 6.0363C11.7261 5.86752 11.6319 5.63865 11.6319 5.4C11.6319 5.16135 11.7261 4.93248 11.8939 4.7637Z"
                    fill="#505050"
                  />
                </svg>
              </span>
              Log out
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
